import React from 'react'
import PropTypes from 'prop-types'
import BlogCard from '../BlogCard'

const BlogPostSidebar = ({ posts }) =>
  // posts.map((post, idx) => (idx === 0 ? <BlogCard post={post} /> : <BlogCardThin post={post} />))
  <></>

BlogPostSidebar.propTypes = {
  posts: PropTypes.arrayOf(BlogCard.propTypes),
}

export default BlogPostSidebar

import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import * as S from './styled'
import BlogSocialShare from '../BlogSocialShare'
import PostSidebar from './PostSidebar'
import { removeHTML } from '@cms/utils'
import ReactHtmlParser from 'react-html-parser'; 
import AuthorBiography from './AuthorBiography'
import DoctorLink from '../../../utils/getDoctorLink'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const BlogPost = ({ post, postImage, relatedPosts, authorData, authorDasa, data }) => {
  const dataQuery = useStaticQuery(graphql`
    query {
      wordpressWpLogo {
        id
        url {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allWordpressCategory(sort: {order: ASC, fields: name}) {
        edges {
          node {
            wordpress_id
            name
          }
        }
      }
      allWordpressWpClinicalStaffPage(sort: { fields: acf___name, order: ASC }) {
        nodes {
          wordpress_id
          slug
          acf {
            council_number
            title_gender
            name
            description
            specialization_area
            usuario
            photo_medic {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [bannerVisible, setBannerVisible] = useState(true);
  const homeData = data.wordpressWpGatsbyPage.acf
  const logoDasa = dataQuery.wordpressWpLogo || false
  const categories = dataQuery.allWordpressCategory ? dataQuery.allWordpressCategory.edges : false
  const postCategory = post.categories.length > 0 ? post.categories[0].name : ''
  const doctors = dataQuery.allWordpressWpClinicalStaffPage
  const author = authorData || false
  const postHtml = post.content || 'Nenhum resultado encontrado'
  const blogPageSlug = getSlugByCountry('blog')

  useEffect(() => {
    const now = new Date()
    const firstPeriod = parseDate(homeData.periodo_inicial);
    const endPeriod = parseDate(homeData.periodo_final);

    function parseDate(dateString) {
      const [datePart, timePart] = dateString.split(' ');
      const [month, day, year] = datePart.split('-').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes);
    }
    
    function showBanner() {
      return now >= firstPeriod && now <= endPeriod;
    }

    return setBannerVisible(showBanner())
  }, [homeData])

  const authors = []

  if(doctors) {
    authors[3] = {
      name: authorDasa.name
    }

    doctors.nodes.map((doctor) => (
      authors[doctor.acf.usuario] = ({
        name: doctor.acf.name,
        image: doctor.acf.photo_medic,
        titleGender: doctor.acf.title_gender,
        slug: doctor.slug
      })
    ))
  }


  return (
    <S.PostContainer>
        <S.BlogPostWrapper>
          <S.BlogPostTitle>
            <S.Categories>
              {postCategory}
            </S.Categories>

            <S.ContainerTitle>
              <S.PostTitle>{post.title.replace(/&#8211;/g, ' - ')}</S.PostTitle>
            </S.ContainerTitle>

            <S.AuthorTop>
              {author && post.author.wordpress_id !== 1
              ?
                <>
                  <S.AuthorNameLink
                    to={DoctorLink({ title: author.title_gender, name: author.name })}
                  >
                    <S.AuthorImgAndNameTop>
                      <S.AuthorImg
                        fluid={author.photo_medic.localFile.childImageSharp.fluid}
                        alt={post.author.name}
                      />

                      <S.AuthorName>
                        {post.author.name}
                      </S.AuthorName>
                    </S.AuthorImgAndNameTop>
                  </S.AuthorNameLink>
                  <S.PostDate>{post.date}</S.PostDate>
                </>
              :
                <>
                  <S.AuthorImgAndNameTop>
                    <S.AuthorImg
                      fluid={logoDasa.url.localFile.childImageSharp.fluid}
                      alt={post.author.name}
                    />

                    <S.AuthorName>
                      {post.author.name}
                    </S.AuthorName>
                  </S.AuthorImgAndNameTop>

                  <S.PostDate>{post.date}</S.PostDate>
                </>
              }
            </S.AuthorTop>
          </S.BlogPostTitle>

          <S.BlogPostContent>
            <S.BlogPostColumnLeft>
              {postImage.featured_media !== null && postImage.featured_media.localFile && (
                <S.ImageFeaturedDiv>
                  <S.ImageFeatured
                    fluid={postImage.featured_media.localFile.childImageSharp.fluid}
                    alt={postImage.featured_media.alt_text || post.title}
                  />
                </S.ImageFeaturedDiv>
              )}

              {postImage.featured_media !== null && postImage.featured_media.caption !== '' && (
                <S.ImageCaption 
                  dangerouslySetInnerHTML={{ __html: postImage.featured_media.caption }} 
                />
              )}

              <S.BlogShare>
                <BlogSocialShare 
                  post={post} 
                  postImage={postImage.featured_media !== null && postImage.featured_media.localFile ? true : false}
                />
              </S.BlogShare>

              {bannerVisible && (
                <S.BannerPostMobileContainer>
                    <S.BannerLink href={homeData.link_banner}>
                      <S.BannerImgMobile
                        fluid={homeData.imagem_mobile.localFile.childImageSharp.fluid} 
                        alt={homeData.imagem_mobile.alt_text}
                      />
                    </S.BannerLink>
                </S.BannerPostMobileContainer>
              )}

              <S.PostContentWrapper>
                <S.PostContent>
                  <div> { ReactHtmlParser (postHtml) } </div>
                </S.PostContent>
                
                <AuthorBiography
                  data={author}
                  post={post}
                  logoDasa={logoDasa}
                />
              </S.PostContentWrapper>

            </S.BlogPostColumnLeft>

            <S.BlogPostColumnRight>
              {bannerVisible && (
                <S.BannerPostDesk>
                  <S.BannerImg
                    fluid={homeData.imagem_desktop.localFile.childImageSharp.fluid} 
                    alt={homeData.imagem_desktop.alt_text}
                  />
                </S.BannerPostDesk>
              )}

              {relatedPosts && relatedPosts.length > 0 && (
                <S.RelatedPosts>
                  <S.RelatedPostsTitle>
                    {homeData.related_post_contents_title}
                  </S.RelatedPostsTitle>

                  <S.RelatedPostsList>
                    {relatedPosts.map(relatedPost => (
                      <S.RelatedPostItem
                        key={relatedPost}
                      >
                        <S.RelatedPostCategory
                          to={`/${blogPageSlug}/?category=${relatedPost.categories[0].name}`}
                        >
                          {relatedPost.categories[0].name}
                        </S.RelatedPostCategory>

                        <S.RelatedPostDate>
                          {relatedPost.date}
                        </S.RelatedPostDate>

                        {relatedPost.featured_media !== null && relatedPost.featured_media.localFile !== null && (
                          <S.RelatedPostImgLink
                            to={`/${blogPageSlug}/${relatedPost.slug}/`}
                          >
                            <S.RelatedPostImg
                              fluid={relatedPost.featured_media.localFile.childImageSharp.fluid} 
                              alt={relatedPost.title}
                            />
                          </S.RelatedPostImgLink>
                        )}

                        <S.RelatedPostTitle
                          to={`/${blogPageSlug}/${relatedPost.slug}/`}
                        >
                          {relatedPost.title}
                        </S.RelatedPostTitle>

                        <S.RelatedPostDescription>
                          <p>{removeHTML(relatedPost.excerpt)}</p>
                        </S.RelatedPostDescription>

                        {typeof(authors[relatedPost]) !== "undefined" && relatedPost.author.wordpress_id !== 1
                        ?
                          <S.RelatedPostAuthor>
                            <S.RelatedPostAuthorImgWrapper>
                              <S.RelatedPostAuthorImg
                                fluid={authors[relatedPost.author.wordpress_id].image.localFile.childImageSharp.fluid}
                                alt={relatedPost.author.name}
                              />
                            </S.RelatedPostAuthorImgWrapper>

                            <S.RelatedPostAuthorName
                              to={DoctorLink({ title: authors[relatedPost.author.wordpress_id].titleGender, name: authors[relatedPost.author.wordpress_id].slug })}
                              link={true}
                            >
                              {relatedPost.author.name}
                            </S.RelatedPostAuthorName>
                          </S.RelatedPostAuthor>
                        :
                          <S.RelatedPostAuthor>
                            <S.RelatedPostAuthorImgWrapper>
                              <S.RelatedPostAuthorImg
                                fluid={logoDasa.url.localFile.childImageSharp.fluid}
                                alt={authorDasa.name}
                              />
                            </S.RelatedPostAuthorImgWrapper>

                            <S.RelatedPostAuthorName>
                              {authorDasa.name}
                            </S.RelatedPostAuthorName>
                          </S.RelatedPostAuthor>
                        }

                      </S.RelatedPostItem>
                    ))}
                  </S.RelatedPostsList>
                </S.RelatedPosts>
              )}

              <S.AllCategories>
                <S.AllCategoriesTitle>
                  {homeData.post_categories_filter_title}
                </S.AllCategoriesTitle>

                <S.ListAllCategories>
                  {categories.map(({node: category}) => (
                    category.wordpress_id !== 1 && (
                      <S.ListItemCategory
                        href={`/${blogPageSlug}/?category=${category.name}`}
                        target="_blank"
                      >
                        {category.name}
                      </S.ListItemCategory>
                    )
                  ))}
                </S.ListAllCategories>
              </S.AllCategories>
            </S.BlogPostColumnRight>    
          </S.BlogPostContent>                    
        </S.BlogPostWrapper>
      </S.PostContainer>
  )
}

BlogPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
    date: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  relatedPosts: PostSidebar.propTypes,
}

export default BlogPost

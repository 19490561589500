import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { ContainerDasa } from '../../shared/container'
import { alvTokens, dasTokens } from '@dasa-health/alma-tokens'
import { BlogAuthor, BlogAuthorLink, BlogAuthorName, BlogImage } from '../BlogCard/styled'
import { CardDate } from '../BlogCardThin/styled'
import { Link } from 'gatsby'

export const PostContainer = styled(ContainerDasa)`
  ${media.lessThan('medium')`
    max-width: 100vw;
    padding: 0;
  `}
`

export const BlogPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BlogPostContent = styled.div`
  display: flex;
  gap: 2.4rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const BlogPostTitle = styled.div`
  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
    margin: 0 0 3.2rem;
    padding: 0 1.6rem;
  `}
`

export const BlogPostColumnLeft = styled.div`
  width: 75.9rem;
`
export const BlogPostColumnRight = styled.div`
  width: 31.1rem;

  ${media.lessThan('medium')`
     width: 100%;
  `}
`

export const PostTitle = styled.h1`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: var(--fontTitle);
  font-weight: 700;
  line-height: 125%;
  margin: 3.2rem 0;
  letter-spacing: -1px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    font-size: var(--font20);
    line-height: 120%;
    margin: 0 0 1.6rem;
  `}
`
export const ContainerTitle = styled.div`
  display: flex;
  align-items: flex-end;
  word-break: break-word;
`

export const RelatedPostsTitle = styled.p`
  font-size: var(--font12);
  text-transform: uppercase;
  color: ${dasTokens.ColorBrandPrimary10};
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  margin-bottom: 2.4rem;
`

export const BlogShare = styled.div`
`

export const PostContentWrapper = styled.div`
  ${media.lessThan('medium')`
    width: 100vw;
    margin-top: 2.4rem;
    padding: 0 1.6rem;
  `}
`

export const PostContent = styled.div`
  width: 75.9rem;
  margin: 2.4rem auto 0;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    margin: 0;
  `}

  p, ul {
      color: ${alvTokens.ColorPaletteGray30};
      font-size: var(--font16);
      line-height: 150%;
      word-break: break-word;
      margin-bottom: 3.2rem;

      ${media.lessThan('medium')`
        font-size: var(--fontDefault);
        line-height: 170%;
        margin-bottom: 2.4rem;
      `}

      &:last-child {
        margin-bottom: 0;
      }
  }

  ol {
    list-style: auto;
    color: ${alvTokens.ColorPaletteGray30};
    font-size: var(--font16);
    line-height: 150%;
    padding-left: 3.2rem;
    margin-bottom: 3.2rem;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      line-height: 170%;
      margin-bottom: 2.4rem;
    `}
  }

  h2, h3, h4 {
    color: ${alvTokens.ColorPaletteGray30};
    font-size: var(--font16);
    line-height: 150%;
    word-break: break-word;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      line-height: 170%;
    `}
  }

  h2 {
    margin-top: 1.6rem;
  }

  h2, h3 {
    font-size: var(--font22);
    margin-bottom: 1.6rem;
  }

  ul {
    list-style: initial;
    padding-inline-start: 3.2rem;
    
    ${media.lessThan('medium')`
      padding-inline-start: 2.5rem;
    `}

    &.blocks-gallery-grid {
      padding-inline-start: 0;

      ${media.lessThan('medium')`
        padding: 2.4rem 0;
      `}
    }

    li {
      &.blocks-gallery-item {
        list-style: none;
        height: 48.5rem;

        ${media.lessThan('medium')`
          max-height: 256px;
        `}

        figure {
          ${media.lessThan('medium')`
            max-height: 256px;
          `}
        }

        img {
          min-width: 75.9rem;
          max-height: 48.5rem;

          ${media.lessThan('medium')`
            min-width: 100%;
            max-height: 25.6rem;
            margin: 0;
          `}
        }
      }

      &::marker {
        font-size: var(--marker);
      }
    }
  }

  a {
    color: #7D7D7D;

    &:hover {
      color: var(--pink);
    }
  }

  figure {
    margin: 0;
    width: 100%;
    max-height: 485px;
    margin-bottom: 3.2rem;

    ${media.lessThan('medium')`
        margin: 0;
    `}

    &.wp-block-gallery {
      margin: 3.2rem 0;

      ${media.lessThan('medium')`
        margin: 0;
      `}
    }

    img {
      width: 100%;
      min-width: 75.9rem;
      max-height: 48.5rem;

      ${media.lessThan('medium')`
        min-width: 100%;
        margin-bottom: 2.4rem;
      `}
    }
  }

  blockquote {
    padding: 1.6rem;
    margin-bottom: 3.2rem;

    ${media.lessThan('medium')`
      margin: 0 .8rem 2.4rem;
    `}
    
    p {
      color: ${dasTokens.ColorBrandPrimary10};
      font-size: var(--font24);
      line-height: 133%;
      
      ${media.lessThan('medium')`
        font-size: var(--font20);
        line-height: 120%;
      `}
    }

  }

  table {
    margin: 3.2rem auto 0;
    border: 1px solid rgba(22, 22, 22, 0.24);

    tr, td {
      color: ${alvTokens.ColorPaletteGray30};
      font-size: var(--font16);
      line-height: 150%;
      padding: .6rem;

      ${media.lessThan('medium')`
        font-size: var(--fontDefault);
        line-height: 170%;
      `}
    }

    td {
      text-align: center;
    }

    tr {
      &:nth-child(odd) {
        background-color: #e7e7e7;
      }
    }

    ${media.lessThan('medium')`
      margin: 2.4rem auto;
    `}
  }

  ${media.lessThan('medium')`
    display:flex;
    flex-direction:column;
  `}

  .wp-block-buttons {
    &.aligncenter {
      justify-content: center;
    }

    &.alignright {
      justify-content: flex-end;
    }
    
    .wp-block-button {
      background-color: var(--pink);
      padding: 1.2rem 1.6rem;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-decoration: none;
      border-radius: 4px;
      width: fit-content;
      margin-bottom: 3.2rem;

      &.btn-2x {
        font-size: 2rem;
        padding: 1.4rem 1.8rem;
      }

      &.btn-3x {
        font-size: 3rem;
        padding: 1.4rem 4.2rem;
      }

      ${media.lessThan('medium')`
        margin-bottom: 2.4rem;
      `}

      a {
        text-decoration: none;
        color: var(--white);
      }
    }
  }
`
export const RelatedPosts = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 2.4rem;

  ${media.lessThan('medium')`
     width: calc(100vw - 3.2rem);
     margin: 0 auto 2.4rem;
  `}
`

export const RelatedPostsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`

export const RelatedPostItem = styled.div`
  padding: 2.4rem 1.6rem;
  background: ${alvTokens.ColorNeutral20};
  border-radius: 4px;
  
  ${media.lessThan('medium')`
    background: var(--gray);
    padding: 1.6rem;
  `}
`

export const RelatedPostCategory = styled(Link)`
  color: var(--pink);
  font-weight: 700;
  font-size: var(--font12);
  letter-spacing: 1.5px;
  line-height: 133%;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
  text-decoration: none;
`

export const RelatedPostDate = styled.p`
  font-size: var(--font12);
  line-height: 133%;
  margin: .8rem 0 1.6rem;
`

export const RelatedPostImgLink = styled(Link)`
  text-decoration: none;
`

export const RelatedPostImg = styled(Img)`
  margin: 2.4rem 0;
  border-radius: 4px;
  max-height: 16rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const RelatedPostTitle = styled(Link)`
  font-weight: 700;
  font-size: var(--font20);
  line-height: 120%;
  color: ${dasTokens.ColorBrandPrimary10};
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 2.4rem 0;
  cursor: pointer;

  &:hover {
    color: var(--pink);
  }

  ${media.lessThan('medium')`
    margin: 1.6rem 0;
  `}
`

export const RelatedPostDescription = styled.div`
  p {
    font-size: var(--font16);
    line-height: 150%;
    height: 7.2rem;
    font-weight: 400;
    color: ${alvTokens.ColorPaletteGray70};
    margin-bottom: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      line-height: 170%;
      height: 7rem;
      -webkit-line-clamp: 3;
      margin-bottom: 1.6rem;
    `}
  }
`

export const RelatedPostAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const RelatedPostAuthorImgWrapper = styled.div`
  div {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0 !important;
  }

  img {
    max-width: 3.2rem;
    max-height: 3.2rem;
  }

  ${media.lessThan('medium')`
    div {
      width: 3.5rem;
      height: 3.5rem;
    }

    img {
      max-width: 3.5rem;
      max-height: 3.5rem;
    }
  `}
`

export const RelatedPostAuthorImg = styled(Img)`
  border-radius: 500px;
`

export const RelatedPostAuthorName = styled(Link)`
  font-weight: 700;
  font-size: var(--fontDefault);
  line-height: 115%;
  color: var(--grayStrong);
  text-decoration: none;
  cursor: ${props => props.link ? 'pointer' : 'default'};

  &:hover {
    color: ${props => props.link ? 'var(--pink)' : 'var(--grayStrong)'};
  }

  ${media.lessThan('medium')`
    line-height: 115%;
    color: ${dasTokens.ColorInteractionBlend08};
  `}
`

export const PostAuthor = styled.p`
  color: var(--pink);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  word-break: break-word;
`

export const PostDate = styled(CardDate)``

export const AuthorPublished = styled.p`
  color: ${alvTokens.ColorPaletteGray30};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  word-break: break-word;
`

export const BannerLink = styled.a``

export const BannerImg = styled(Img)`
  width: 31.1rem;
  height: 32rem;
`

export const BannerImgMobile = styled(Img)`
  width: 28.8rem;
  height: 32rem;
`

export const AuthorTop = styled(BlogAuthor)`
  justify-content: space-between;
  margin-bottom: 3.2rem;
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    gap: 1.6rem;
    margin: 0;
  `}
`

export const AuthorBioWrapper = styled.div`
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
  `}
`

export const AuthorBioContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 3.2rem;
  gap: 1.6rem;
  color: var(--grayStrong);
  border-top: 1px solid rgba(22, 22, 22, 0.24);
  margin-top: 3.2rem;
  margin-bottom: ${props => !props.bio ? '8rem' : '0'};

  ${media.lessThan('medium')`
    margin-top: 2.4rem;
    margin-bottom: 0;
    padding-top: 2.4rem;
  `}
`

export const AuthorBio = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 1.6rem;
  margin-bottom: 8rem;
  
  ${media.lessThan('medium')`
    margin-bottom: 0;
  `}

  p {
    color: ${alvTokens.ColorPaletteGray30};
    font-size: var(--font16);
    font-weight: 400;
    line-height: 150%;
    
    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      line-height: 170%;
    `}
  }
`

export const AuthorNameLink = styled(BlogAuthorLink)``

export const AuthorNameLinkBiography = styled(Link)`
  text-decoration: none;
  color: var(--grayStrong);
`

export const AuthorName = styled(BlogAuthorName)``

export const AuthorNameBiography = styled.p`
  line-height: 115%;
  font-weight: 700;
  font-size: var(--fontDefault);
`

export const AuthorImg = styled(BlogImage)``

export const AuthorImgBiography = styled(Img)`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 1.45rem;

  ${media.lessThan('medium')`
    width: 3.2rem;
    height: 3.2rem;
  `}
`

export const AuthorImgAndNameTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

export const AuthorImgAndName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

export const AuthorImgAndNameBiography = styled(AuthorImgAndName)``

export const ImageFeaturedDiv = styled.div`
  ${media.lessThan('medium')`
    max-width: 100vw;
  `}
`

export const ImageFeatured = styled(Img)`
  height: 48rem;

  ${media.lessThan('medium')`
    max-width: 100vw;
    height: 26.3rem;
  `}
`

export const ImageCaption = styled.div`
  background: var(--pink);
  padding: 1.6rem;

  ${media.lessThan('medium')`
    width: 100vw;
    max-width: calc(100vw - 3.2rem);
  `}

  p {
    color: var(--white);
    font-size: var(--font24);
    line-height: 133%;
    
    ${media.lessThan('medium')`
      font-size: var(--font20);
      line-height: 120%;
    `}
  }
`

export const Categories = styled.p`
  font-size: var(--font12);
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  color: var(--pink);
  text-transform: uppercase;
  margin-top: 1rem;
  
  ${media.lessThan('medium')`
    margin: 0 0 2.4rem;
  `}
`

export const BannerPostDesk = styled.div`
  height: 32rem;
  margin: 0 0 2.4rem;

  @media (max-width: 768px) {
    display: none;
  }
`

export const BannerPostMobileContainer = styled.div`
  display: none;
  width: 100vw;
  margin-top: 2.4rem;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`

export const AllCategories = styled.div`
  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    padding: 0 1.6rem;
    margin-top: 2.4rem;
  `}
`

export const AllCategoriesTitle = styled.p`
  color: ${dasTokens.ColorBrandPrimary10};
  font-weight: 700;
  font-size: var(--font12);
  line-height: 133%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 2.4rem;
  
  ${media.lessThan('medium')`
    margin-bottom: 0;
  `}
`

export const ListAllCategories = styled.div`
  margin-bottom: 3.2rem;
`

export const ListItemCategory = styled(Link)`
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: ${dasTokens.ColorPaletteGray30};
  font-weight: 700;
  font-size: var(--font16);
  line-height: 150%;
  padding: 1.6rem;
  border-bottom: 1px solid rgba(22, 22, 22, 0.24);

  &:hover {
    color: var(--pink);
  }
`

import styled from 'styled-components'
import { WhatsappIcon } from 'react-share'
import media from 'styled-media-query'
import { dasTokens } from '@dasa-health/alma-tokens'

export const SocialShareIcons = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 1.6rem 2.4rem;
  margin: ${props => props.postImage ? '3.2rem 0 0' : 0};

  ${media.lessThan('medium')`
    display: none;
  `}

  button {
    svg {
      background: none;

      circle, rect {
        fill: none;
      }

      path {
        fill: var(--pink);
      }
    }
  } 
`

export const ShareLabel = styled.p`
  color: ${dasTokens.ColorBrandBase01};
  font-size: var(--font16);
  line-height: 150%;
  font-weight: 500;
  margin-right: 2.4rem;
`

export const ShareButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const StyledWhatsappIcon = styled(WhatsappIcon)`
  margin-right: 1rem;
`

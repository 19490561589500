import React from 'react'
import PropTypes from 'prop-types'
import {
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Linkedin, Facebook, Whatsapp, Twitter } from '@styled-icons/fa-brands'
import IconWrapper from '../../shared/IconWrapper'
import { removeHTML } from '@cms/utils'
import * as S from './styled'
import getUrlsByCountry from '../../../utils/getUrlsByCountry'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const BlogSocialShare = ({ post, postImage }) => {
  const routes = getUrlsByCountry(process.env.GATSBY_COUNTRY)
  const urlProd = routes.production
  const blogPageSlug = getSlugByCountry('blog')
  return (
    <S.SocialShareIcons postImage={postImage}>
      <S.ShareLabel>Compartilhe este artigo: </S.ShareLabel>
      <S.ShareButtons>
        <LinkedinShareButton
          title={post.title}
          summary={`${removeHTML(post.excerpt)}`}
          url={`${urlProd}/${blogPageSlug}/${post.slug}`}
        >
          <IconWrapper Icon={Linkedin} size="23" />
        </LinkedinShareButton>
        <FacebookShareButton
          url={`${urlProd}/${blogPageSlug}/${post.slug}`}
          quote={`${removeHTML(post.excerpt)}`}
        >
          <IconWrapper Icon={Facebook} size="23" />
        </FacebookShareButton>
        <WhatsappShareButton
          url={`${urlProd}/${blogPageSlug}/${post.slug}`}
          title={post.title}
          separator={(`${post.title}`, ` ${removeHTML(post.excerpt)}`)}
        >
          <IconWrapper Icon={Whatsapp} size="23" />
        </WhatsappShareButton>
        <TwitterShareButton url={`${urlProd}/${blogPageSlug}/${post.slug}`} title={post.title}>
          <IconWrapper Icon={Twitter} size="23" />
        </TwitterShareButton>
      </S.ShareButtons>
    </S.SocialShareIcons>
  )
}

BlogSocialShare.propTypes = {
  post: PropTypes.shape({
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default BlogSocialShare

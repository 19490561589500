import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { removeHTML } from '@cms/utils'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Post from '../components/blog/BlogPost'
import PinkLine from '../components/shared/PinkLine'

const BlogPost = ({ data, location, pageContext }) => {
  const { postData: post } = pageContext
  const seoData = post.acf

  const {
    wordpressWpGatsbyPage: {
      title
    },
  } = data

  const authorDasaData = data.wordpressWpUsers

  const authorDasaGenomica = {
    name: authorDasaData.name,
  }

  const authorData = data.wordpressWpClinicalStaffPage !== null ? data.wordpressWpClinicalStaffPage.acf : undefined
  const relatedPosts = data.relatedPosts.edges.map(edge => edge.node)
  const postImage = data.wordpressPost
  const hrefLangLinks = data.wordpressPost.acf.hreflang

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={seoData.title || title}
        description={seoData.description || removeHTML(post.excerpt)}
        canonical={seoData.canonical}
        hrefLangLinks={hrefLangLinks}
      />
      <Post 
        post={post}
        postImage={postImage}
        relatedPosts={relatedPosts}
        authorData={authorData}
        authorDasa={authorDasaGenomica}
        data={data}
      />
      <PinkLine />
    </Layout>
  )
}

export const query = graphql`
  query($postId: Int, $user: String, $category: Int, $pageSlug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $pageSlug }) {
      title
      acf {
        title
        description
        imagem_desktop {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imagem_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        link_banner
        periodo_inicial
        periodo_final
        related_post_contents_title
        post_categories_filter_title
      }
    }
    wordpressWpClinicalStaffPage(acf: {usuario: {eq: $user}}) {
      id
      path
      acf {
        usuario
        name
        title_gender
        biography
        photo_medic {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    wordpressPost(wordpress_id: {eq: $postId}) {
      featured_media {
        caption
        alt_text
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        hreflang {
          post_hreflang_ar
          post_hreflang_br
        }
      }
    }
    wordpressWpUsers(wordpress_id: {eq: 1}) {
      id
      name
      wordpress_id
    }
    wordpressWpLogo {
      id
      url {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    relatedPosts: allWordpressPost(
      limit: 2,
      filter: {categories: {elemMatch: {wordpress_id: {eq: $category}}}, wordpress_id: {ne: $postId}}
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          excerpt
          date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
          title
          wordpress_id
          author {
            wordpress_id
            name
          }
          categories {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 432) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

BlogPost.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default BlogPost

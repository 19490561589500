import React from 'react'
import * as S from './styled'
import DoctorLink from '../../../utils/getDoctorLink'

const AuthorBiography = (props) => {
  const doctorBiography = props.data && props.data.biography ? props.data.biography : false

  return (
      <S.AuthorBioWrapper>
         <S.AuthorBioContent bio={doctorBiography}>
          {props.data && props.post.author.wordpress_id !== 1
            ?
             <>
               <S.AuthorNameLinkBiography
                 to={DoctorLink({ title: props.data.title_gender, name: props.data.name })}
               >
                 <S.AuthorImgAndNameBiography>
                   <S.AuthorImgBiography
                     fluid={props.data.photo_medic.localFile.childImageSharp.fluid}
                     alt={props.post.author.name}
                   />

                   <S.AuthorNameBiography>
                     {props.post.author.name}
                   </S.AuthorNameBiography>
                 </S.AuthorImgAndNameBiography>

                 {doctorBiography && (
                   <S.AuthorBio dangerouslySetInnerHTML={{ __html: doctorBiography }} />
                 )}
               </S.AuthorNameLinkBiography>
             </>
           :
             <>
               <S.AuthorImgAndNameBiography>
                 <S.AuthorImgBiography
                   fluid={props.logoDasa.url.localFile.childImageSharp.fluid}
                   alt={props.post.author.name}
                 />

                 <S.AuthorNameBiography>
                   {props.post.author.name}
                 </S.AuthorNameBiography>
               </S.AuthorImgAndNameBiography>
             </>
           }
         </S.AuthorBioContent>
      </S.AuthorBioWrapper>      
  )
}

export default AuthorBiography
